import React from "react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  VStack,
  HStack,
  Link,
  Image,
  Heading,
  Button,
  Icon,
} from "@chakra-ui/react";
// import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
  FaTelegramPlane,
  FaEnvelope,
  FaLinkedinIn,
  FaFacebookF,
} from "react-icons/fa";
import ratingrescue from "../assets/icons/ratingrescue.svg";
import {
  primary,
  secondary,
  textBlack,
  textPrimary,
  white,
  yellow,
} from "../utils/colors";
import strmavenlogosecondary from "../assets/icons/strmavenlogo-secondary.svg";
import { FaE, FaXTwitter } from "react-icons/fa6";
import { shade } from "polished";

const Footer = () => {
  return (
    <Box w="100%" p="1rem">
      <Box bg={textBlack} w="100%" borderRadius="1.5rem">
        <Flex
          direction="column"
          maxW={["100%", "100%", "1200px"]}
          justify="space-between"
          m="0 auto"
          p={["3rem 1.5rem 3rem", "3rem 1.5rem 3rem", "3rem 0 3rem"]}
        >
          <Flex
            w="100%"
            align="center"
            justify="center"
            m="0 auto"
            direction="column"
            textAlign="center"
          >
            <Heading
              fontSize={["2rem", "2rem", "4rem"]}
              color={yellow}
              fontWeight="800"
              maxW={["100%", "100%", "500px"]}
              m="0 0 3rem"
            >
              Remove Negative Airbnb Reviews
            </Heading>
          </Flex>

          <Flex
            align="center"
            justify={["center", "center", "space-around"]}
            w="100%"
            direction={["column-reverse", "column-reverse", "row"]}
          >
            <Flex align={["center", "center", "flex-start"]} direction="column">
              <Image
                src={strmavenlogosecondary}
                alt="STRMaven Reviews"
                h="2.25rem"
                position="relative"
                // top="-3px"
                m={["0 auto", "0 auto", "0 0.5rem 0 0"]}
              />

              <Text
                fontSize="1.125rem"
                color={white}
                fontWeight="500"
                m={["1rem auto", "1rem auto", "1rem 0"]}
              >
                Boost your ratings guaranteed.
              </Text>

              <Flex
                gap="1rem"
                justify={["center", "center", "flex-start"]}
                w={["100%", "100%", "initial"]}
              >
                <Link
                  href="https://www.linkedin.com/company/str-maven/"
                  target="_blank"
                >
                  <IconButton
                    as={Link}
                    size="sm"
                    target="_blank"
                    aria-label="Linkedin"
                    icon={<FaLinkedinIn />}
                    bg="#333"
                    color={white}
                    fontSize="1rem"
                    _hover={{
                      textDecoration: "none",
                      bg: white,
                      color: textBlack,
                    }}
                  />
                </Link>

                <Link href="https://instagram.com/str_maven" target="_blank">
                  <IconButton
                    size="sm"
                    target="_blank"
                    aria-label="Instagram"
                    icon={<FaInstagram />}
                    bg="#333"
                    color={white}
                    fontSize="1rem"
                    _hover={{
                      textDecoration: "none",
                      bg: white,
                      color: textBlack,
                    }}
                  />
                </Link>

                <Link href="https://www.facebook.com/strmaven" target="_blank">
                  <IconButton
                    size="sm"
                    target="_blank"
                    aria-label="Facebook"
                    icon={<FaFacebookF />}
                    bg="#333"
                    color={white}
                    fontSize="1rem"
                    _hover={{
                      textDecoration: "none",
                      bg: white,
                      color: textBlack,
                    }}
                  />
                </Link>
                {/* <IconButton
                  as={Link}
                  size="sm"
                  target="_blank"
                  aria-label="Twitter/X"
                  icon={<FaXTwitter />}
                  bg="#333"
                  color={white}
                  fontSize="1rem"
                /> */}
              </Flex>

              <Text
                fontSize="sm"
                color={white}
                m={["1.5rem 0", "1.5rem 0", "1rem 0"]}
              >
                &copy; {new Date().getFullYear()} STRMaven
              </Text>
            </Flex>

            <Flex
              align={["center", "center", "flex-start"]}
              direction="column"
              m={["0rem 0 3rem", "0rem 0 3rem", "initial"]}
            >
              {/* <Text fontSize="md" color={white}>
                Contact us
              </Text> */}
              <Flex
                w="100%"
                justify="center"
                m="0rem 0"
                gap="1rem"
                direction={["column", "column", "row"]}
              >
                <Link href="https://wa.link/str-maven" target="_blank">
                  <Button
                    bg="#25D366"
                    color={white}
                    size="md"
                    h="3rem"
                    px="2rem"
                    borderRadius="10rem"
                    _hover={{
                      textDecoration: "none",
                      bg: shade(0.3, "#25D366"),
                    }}
                    minW={["260px", "260px", "initial"]}
                  >
                    <Icon
                      as={FaWhatsapp}
                      color={white}
                      fontSize="1.5rem"
                      mr={2}
                    />
                    <Heading fontSize="md" color={white} fontWeight="600">
                      Chat on WhatsApp
                    </Heading>
                  </Button>
                </Link>

                <Link href="https://t.me/strmaven" target="_blank">
                  <Button
                    // href="https://t.me/strmaven"
                    bg="#0088cc"
                    color={white}
                    size="md"
                    h="3rem"
                    px="2rem"
                    borderRadius="10rem"
                    _hover={{
                      textDecoration: "none",
                      bg: shade(0.3, "#0088cc"),
                    }}
                    minW={["260px", "260px", "initial"]}
                  >
                    <Icon
                      as={FaTelegramPlane}
                      color={white}
                      fontSize="1.5rem"
                      mr={2}
                    />
                    <Heading fontSize="md" color={white} fontWeight="600">
                      Chat on Telegram
                    </Heading>
                  </Button>
                </Link>

                <Link href="mailto:hello@strmaven.com" target="_blank">
                  <Button
                    bg={yellow}
                    color={textBlack}
                    size="md"
                    h="3rem"
                    px="2rem"
                    borderRadius="10rem"
                    _hover={{ textDecoration: "none", bg: shade(0.3, yellow) }}
                    minW={["260px", "260px", "initial"]}
                  >
                    <Icon
                      as={FaEnvelope}
                      color={textBlack}
                      fontSize="1.25rem"
                      mr={2}
                    />
                    <Heading fontSize="md" color={textBlack} fontWeight="600">
                      hello@strmaven.com
                    </Heading>
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/* <Flex direction="column" p="0rem 0">
          <Divider borderColor="gray.600" />
          <Text textAlign="center" fontSize="sm" color="gray.500" p="3rem 0 ">
            &copy; {new Date().getFullYear()} Company Name. All rights reserved.
          </Text>
        </Flex> */}
      </Box>
    </Box>
  );
};

export default Footer;
