import React from "react";
import { Flex, Heading, Text, Button, Link, Icon } from "@chakra-ui/react";
import { white, yellow, textBlack } from "../utils/colors";
// import { Link } from "react-router-dom";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import { shade } from "polished";

const Cta = () => {
  return (
    <Flex w="100%" p={["5rem 0", "5rem 0", "8rem 0"]}>
      <Flex
        w="100%"
        maxW={["100%", "100%", "1200px"]}
        m="0 auto"
        justify="space-between"
        bg={yellow}
        borderRadius="1.5rem"
        p={["4rem 1.5rem", "4rem 1.5rem", "6rem 3rem"]}
        direction="column"
      >
        <Flex
          w="100%"
          align="center"
          justify="center"
          m="0 auto"
          direction="column"
          textAlign="center"
        >
          <Heading
            fontSize={["4rem", "4rem", "5rem"]}
            color={textBlack}
            fontWeight="800"
          >
            Have questions?
          </Heading>
          <Text
            fontSize="1.25rem"
            color={textBlack}
            textAlign="center"
            m="1rem auto"
            maxW="640px"
            fontWeight="500"
            lineHeight="1.8"
          >
            Contact us at{" "}
            <Link
              href="tel:+17628890778"
              bg={textBlack}
              p="0.25rem 0.5rem"
              borderRadius="1rem"
              color={white}
              fontSize="1.125rem"
              fontWeight="600"
              transition="color 0.3s"
              _hover={{ color: textBlack, bg: white, textDecoration: "none" }}
            >
              +1 762-889-0778
            </Link>{" "}
            or{" "}
            <Link
              href="mailto:hello@strmaven.com"
              target="_blank"
              bg={textBlack}
              p="0.25rem 0.5rem"
              borderRadius="1rem"
              fontSize="1.125rem"
              color={white}
              fontWeight="600"
              transition="color 0.3s"
              _hover={{ color: textBlack, bg: white, textDecoration: "none" }}
            >
              hello@strmaven.com
            </Link>{" "}
            or choose one of the options
          </Text>
        </Flex>

        <Flex
          w="100%"
          justify="center"
          m="2rem 0"
          gap="1rem"
          direction={["column", "column", "row"]}
        >
          <Link href="https://wa.link/str-maven" target="_blank">
            <Button
              bg="#25D366"
              color={white}
              size="lg"
              h="3.5rem"
              px="2rem"
              borderRadius="10rem"
              _hover={{ textDecoration: "none", bg: shade(0.3, "#25D366") }}
              w={["100%", "100%", "initial"]}
            >
              <Icon as={FaWhatsapp} color={white} fontSize="1.5rem" mr={2} />
              <Heading fontSize="1.125rem" color={white} fontWeight="600">
                Chat on WhatsApp
              </Heading>
            </Button>
          </Link>

          <Link href="https://t.me/strmaven" target="_blank">
            <Button
              bg="#0088cc"
              color={white}
              size="lg"
              h="3.5rem"
              px="2rem"
              borderRadius="10rem"
              _hover={{ textDecoration: "none", bg: shade(0.3, "#0088cc") }}
              w={["100%", "100%", "initial"]}
            >
              <Icon
                as={FaTelegramPlane}
                color={white}
                fontSize="1.5rem"
                mr={2}
              />
              <Heading fontSize="1.125rem" color={white} fontWeight="600">
                Chat on Telegram
              </Heading>
            </Button>
          </Link>

          {/* <Button
            bg={primary}
            color={white}
            size="lg"
            h="3.5rem"
            px="2rem"
            borderRadius="10rem"
            _hover={{ textDecoration: "none", bg: secondary }}
          >
            <Icon as={FaEnvelope} color={white} fontSize="1.25rem" mr={2} />
            Email us
          </Button> */}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Cta;
