import {
  Flex,
  Heading,
  Image,
  IconButton,
  Text,
  Icon,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import {
  textSecondary,
  white,
  secondary,
  textBlack,
  yellow,
  green,
} from "../utils/colors";
// import { Link } from "react-router-dom";
import {
  FaWhatsapp,
  FaTelegramPlane,
  FaEnvelope,
  FaStripe,
  FaPaypal,
  FaShieldAlt,
} from "react-icons/fa";
import {
  FaClockRotateLeft,
  FaMoneyBillTransfer,
  FaShield,
} from "react-icons/fa6";
import arrow1 from "../assets/icons/arrow1.svg";
import { shade } from "polished";
import paypallogo from "../assets/icons/paypallogo.svg";
import visalogo from "../assets/icons/visalogo.svg";
import mastercardlogo from "../assets/icons/mastercardlogo.svg";
import bitcoinlogo from "../assets/icons/bitcoinlogo.svg";
import moneytransferlogo from "../assets/icons/moneytransfer.svg";
import banktransferlogo from "../assets/icons/banktransfer.svg";

const HowItWorks = () => {
  return (
    <Flex w="100%" m="0rem 0 0">
      <Flex
        w="100%"
        maxW={["100%", "100%", "1200px"]}
        m="0 auto"
        p={["0 1.5rem", "0 1.5rem", "initial"]}
        direction="column"
        // bg="#F7F1E3"
        // borderRadius="3rem"
      >
        <Flex
          w="100%"
          align="center"
          justify="center"
          m="0 auto"
          direction="column"
          textAlign="center"
        >
          <Heading fontSize="3.5rem" color={textBlack} fontWeight="800">
            How It Works
          </Heading>
          <Text
            fontSize={["1.125rem", "1.125rem", "1.25rem"]}
            color={textBlack}
            textAlign="center"
            m="1rem auto"
            maxW={["100%", "100%", "640px"]}
            fontWeight="500"
          >
            Our specialized Review Deletion Service helps you mainrciently,
            allowing you to focus on providing great guest experiences.
          </Text>
        </Flex>

        <Flex
          w="100%"
          justify="space-between"
          m="3rem 0 0"
          direction={["column", "column", "row"]}
        >
          <Flex
            w={["100%", "100%", "46%"]}
            direction="column"
            border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="4rem 3rem"
            align="flex-start"
          >
            <Flex
              bg={yellow}
              align="center"
              justify="center"
              textAlign="center"
              h="2.5rem"
              w="2.5rem"
              borderRadius="1rem"
            >
              <Text fontSize="1.5rem" color={textBlack} fontWeight="700">
                1
              </Text>
            </Flex>

            <Heading fontSize="2.25rem" color={textBlack} m="1.5rem 0 1rem">
              Submit a Request
            </Heading>
            <Text fontSize="1.125rem" color={textSecondary} maxW="400px">
              Provide us with the information about the review through one of
              our contact channels.
            </Text>

            <Flex w="100%" m="2rem 0 0" gap="1rem">
              <Link href="https://wa.link/str-maven" target="_blank">
                <IconButton
                  target="_blank"
                  aria-label="WhatsApp"
                  icon={<FaWhatsapp />}
                  bg="#25D366"
                  color={white}
                  fontSize="1.25rem"
                  _hover={{ textDecoration: "none", bg: shade(0.3, "#25D366") }}
                />
              </Link>

              <Link href="https://t.me/strmaven" target="_blank">
                <IconButton
                  target="_blank"
                  aria-label="Telegram"
                  icon={<FaTelegramPlane />}
                  bg="#0088cc"
                  color={white}
                  _hover={{ bg: shade(0.3, "#0088cc") }}
                  fontSize="1.25rem"
                />
              </Link>

              <Link href="mailto:hello@strmaven.com" target="_blank">
                <IconButton
                  target="_blank"
                  aria-label="Email"
                  icon={<FaEnvelope />}
                  bg={yellow}
                  color={textBlack}
                  _hover={{ bg: shade(0.3, yellow) }}
                  fontSize="1.125rem"
                />
              </Link>
            </Flex>
          </Flex>
          <Flex
            w={["20%", "20%", "50%"]}
            direction="column"
            // border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="2rem"
            align="flex-start"
            justify="flex-end"
            position="relative"
            display={["none", "none", "flex"]}
          >
            <Image
              src={arrow1}
              h="16rem"
              opacity="0.8"
              position="absolute"
              left="-5rem"
              transform="
            rotate(-180deg)"
              pointerEvents="none"
              _focus={{
                outline: "none",
              }}
            />
          </Flex>
        </Flex>

        <Flex
          w="100%"
          justify="space-between"
          m={["3rem 0 0", "3rem 0 0", "-6rem 0 0"]}
        >
          <Flex
            w="50%"
            direction="column"
            // border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="0rem"
            display={["none", "none", "flex"]}
          ></Flex>
          <Flex
            w={["100%", "100%", "46%"]}
            direction="column"
            border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="4rem 3rem"
            align="flex-start"
          >
            <Flex
              bg={yellow}
              align="center"
              justify="center"
              textAlign="center"
              h="2.5rem"
              w="2.5rem"
              borderRadius="1rem"
            >
              <Text fontSize="1.5rem" color={textBlack} fontWeight="700">
                2
              </Text>
            </Flex>

            <Heading fontSize="2.25rem" color={textBlack} m="1.5rem 0 1rem">
              Removal Process
            </Heading>
            <Text fontSize="1.125rem" color={textSecondary} maxW="400px">
              Our team handles everything afterwards, there is no need for your
              input.
            </Text>

            <Flex
              w="100%"
              m="2rem 0 0"
              gap="1rem"
              align={["flex-start", "flex-start", "center"]}
              direction={["column", "column", "row"]}
            >
              <Flex align="center">
                <Icon
                  as={FaShieldAlt}
                  color={green}
                  fontSize="1.5rem"
                  mr="0.5rem"
                />
                <Text fontSize="md" color={textBlack} fontWeight="600">
                  Payment After Success
                </Text>
              </Flex>

              <Flex align="center">
                <Icon
                  as={FaClockRotateLeft}
                  color={green}
                  fontSize="1.5rem"
                  mr="0.5rem"
                />
                <Text fontSize="md" color={textBlack} fontWeight="600">
                  Fast Turnaround (72h)
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          w="100%"
          justify="space-between"
          m={["3rem 0 0", "3rem 0 0", "-6rem 0 0"]}
        >
          <Flex
            w={["100%", "100%", "50%"]}
            direction="column"
            border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="4rem 3rem"
            align="flex-start"
          >
            <Flex
              bg={yellow}
              align="center"
              justify="center"
              textAlign="center"
              h="2.5rem"
              w="2.5rem"
              borderRadius="1rem"
            >
              <Text fontSize="1.5rem" color={textBlack} fontWeight="700">
                3
              </Text>
            </Flex>

            <Heading fontSize="2.25rem" color={textBlack} m="1.5rem 0 1rem">
              Confirmation & Payment
            </Heading>
            <Text fontSize="1.125rem" color={textSecondary} maxW="400px">
              Your review will be removed successfully, you can proceed with
              providing us with payment for service.
            </Text>

            <Flex
              w="100%"
              m="2rem 0 0"
              gap="2rem"
              align="center"
              wrap={["wrap", "wrap", "nowrap"]}
            >
              <Image src={visalogo} h="1.125rem" />
              <Image src={mastercardlogo} h="1.5rem" />
              <Image src={paypallogo} h="1.25rem" />
              <Icon as={FaStripe} color="#008cdd" fontSize="3.75rem" />
              <Tooltip
                label="BTC, ETH, USDT, other"
                p="0.5rem 1rem"
                borderRadius="1rem"
                textAlign="center"
                placement="top"
              >
                <Image src={bitcoinlogo} h="2rem" />
              </Tooltip>
              <Tooltip
                label="Bank transfer"
                p="0.5rem 1rem"
                borderRadius="1rem"
                textAlign="center"
                placement="top"
              >
                <Image src={banktransferlogo} h="1.5rem" />
              </Tooltip>
            </Flex>
          </Flex>
          <Flex
            w="50%"
            direction="column"
            // border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="2rem"
            align="flex-start"
            justify="flex-start"
            position="relative"
            display={["none", "none", "flex"]}
          >
            <Image
              src={arrow1}
              h="16rem"
              opacity="0.8"
              position="absolute"
              left="-5rem"
              transform="
            rotate(-45deg)"
              pointerEvents="none"
              _focus={{
                outline: "none",
              }}
            />
          </Flex>
        </Flex>

        {/* <Flex w="100%" gap="3rem" m="3rem 0 0">
          <Flex
            w="25%"
            direction="column"
            border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="1.5rem"
          >
            <Flex
              // bg={white}
              align="center"
              justify="center"
              textAlign="center"
              h="2.5rem"
              w="2.5rem"
              borderRadius="1rem"
              m="0 auto"
            >
              <Text fontSize="2rem" color={primary} fontWeight="600">
                1
              </Text>
            </Flex>
            <Flex direction="column" align="center" m="1.25rem 0 0">
              <Heading
                fontSize="1.25rem"
                color={textPrimary}
                fontWeight="600"
                m="0 0 0.5rem"
              >
                Submit a Request
              </Heading>
              <Text fontSize="md" color={textSecondary} textAlign="center">
                Provide us with the details of the review you want to remove.
              </Text>
            </Flex>
          </Flex>

          <Flex
            w="25%"
            direction="column"
            border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="1.5rem"
          >
            <Flex
              // bg={white}
              align="center"
              justify="center"
              textAlign="center"
              h="2.5rem"
              w="2.5rem"
              borderRadius="1rem"
              m="0 auto"
            >
              <Text fontSize="2rem" color={primary} fontWeight="600">
                2
              </Text>
            </Flex>
            <Flex direction="column" align="center" m="1.25rem 0 0">
              <Heading
                fontSize="1.25rem"
                color={textPrimary}
                fontWeight="600"
                m="0 0 0.5rem"
              >
                Review Analysis
              </Heading>
              <Text fontSize="md" color={textSecondary} textAlign="center">
                Our team assesses the review and formulates a strategy
              </Text>
            </Flex>
          </Flex>

          <Flex
            w="25%"
            direction="column"
            border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="1.5rem"
          >
            <Flex
              // bg={white}
              align="center"
              justify="center"
              textAlign="center"
              h="2.5rem"
              w="2.5rem"
              borderRadius="1rem"
              m="0 auto"
            >
              <Text fontSize="2rem" color={primary} fontWeight="600">
                3
              </Text>
            </Flex>
            <Flex direction="column" align="center" m="1.25rem 0 0">
              <Heading
                fontSize="1.25rem"
                color={textPrimary}
                fontWeight="600"
                m="0 0 0.5rem"
              >
                Removal Process
              </Heading>
              <Text fontSize="md" color={textSecondary} textAlign="center">
                We handle all and procedures with platform to get the review
                removed
              </Text>
            </Flex>
          </Flex>

          <Flex
            w="25%"
            direction="column"
            border={`1px dashed ${secondary}`}
            borderRadius="1.5rem"
            p="1.5rem"
          >
            <Flex
              // bg={white}
              align="center"
              justify="center"
              textAlign="center"
              h="2.5rem"
              w="2.5rem"
              borderRadius="1rem"
              m="0 auto"
            >
              <Text fontSize="2rem" color={primary} fontWeight="600">
                4
              </Text>
            </Flex>
            <Flex direction="column" align="center" m="1.25rem 0 0">
              <Heading
                fontSize="1.25rem"
                color={textPrimary}
                fontWeight="600"
                m="0 0 0.5rem"
              >
                Confirmation
              </Heading>
              <Text fontSize="md" color={textSecondary} textAlign="center">
                Once the review is removed, we notify you immediately.
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex w="100%" p="3rem 0 0" gap="2rem" align="center" justify="center">
          <Flex align="center">
            <Icon
              as={FaShieldAlt}
              color={secondary}
              fontSize="1.25rem"
              mr="0.5rem"
            />
            <Text fontSize="1.125rem" color={textPrimary} fontWeight="600">
              Safe & Secure
            </Text>
          </Flex>

          <Flex align="center">
            <Icon
              as={FaWallet}
              color={secondary}
              fontSize="1.25rem"
              mr="0.5rem"
            />
            <Text fontSize="1.125rem" color={textPrimary} fontWeight="600">
              Pay After Success
            </Text>
          </Flex>
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default HowItWorks;
