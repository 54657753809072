import React, { useEffect } from "react";
import {
  Flex,
  Text,
  Heading,
  Image,
  Icon,
  Button,
  Link,
  useBreakpointValue,
} from "@chakra-ui/react";
import { white, textBlack } from "../utils/colors";
import { useMediaQuery } from "react-responsive";
import { FaWhatsapp, FaTelegramPlane } from "react-icons/fa";
import heroicon1 from "../assets/icons/heroicon1.svg";
import heroicon2 from "../assets/icons/heroicon2.svg";
import heroicon3 from "../assets/icons/heroicon3.svg";
import airbnb from "../assets/icons/airbnblogo.svg";
import { shade } from "polished";

const Hero = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  console.log("isMobile", isMobile);

  return (
    <>
      <Flex w="100%" direction="column" position="relative">
        <Flex
          w="100%"
          h="100%"
          minH="90vh"
          maxH="100%"
          align="center"
          justify="flex-start"
          position="relative"
          zIndex={9}
          p={["0 1.5rem 4rem", "0 1.5rem 4rem", "0 0 4rem"]}
          // bg={yellow}
          bg="#F7F1E3"
        >
          <Flex
            direction="column"
            w="100%"
            maxW={["100%", "100%", "1200px"]}
            margin="0 auto"
            textAlign="left"
            p={["10rem 0rem 0", "10rem 0rem 0", "10rem 0 0"]}
          >
            <Flex
              w="100%"
              justify="center"
              align="center"
              direction="column"
              textAlign="center"
            >
              <Flex
                direction="column"
                align="flex-start"
                maxW={["100%", "100%", "900px"]}
              >
                <Heading
                  fontSize={["5rem", "5rem", "7rem"]}
                  color={textBlack}
                  fontWeight="800"
                  lineHeight="1"
                  w={["100%", "100%", "initial"]}
                >
                  Remove Negative Airbnb Reviews
                </Heading>

                <Flex
                  align="center"
                  m="1rem auto 2.5rem"
                  direction={["column", "column", "row"]}
                >
                  <Text fontSize="1.25rem" color={textBlack} fontWeight="600">
                    {isMobile
                      ? "  Boost your ratings by removing negative reviews from"
                      : "  Boost your ratings by removing negative"}
                  </Text>
                  <Image
                    src={airbnb}
                    h={["2rem", "2rem", "1.75rem"]}
                    px="0.5rem"
                    m={["1rem 0 0", "1rem 0 0", "0 0 0"]}
                  />
                  <Text
                    fontSize="1.25rem"
                    color={textBlack}
                    fontWeight="600"
                    display={["none", "none", "block"]}
                  >
                    reviews
                  </Text>
                </Flex>

                {/* <Flex align="center" justify="center" m="0 auto">
                  <Button
                    bg={primary}
                    color={white}
                    size="lg"
                    h="3.5rem"
                    px="2rem"
                    borderRadius="10rem"
                  >
                    How it works
                  </Button>
                </Flex> */}

                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  m="0 auto"
                  w={["100%", "100%", "initial"]}
                >
                  <Heading
                    fontSize="1.25rem"
                    fontWeight="600"
                    m="0 0 1rem"
                    color={textBlack}
                    letterSpacing="1px"
                    w={["100%", "100%", "initial"]}
                  >
                    Remove unfair reviews in 24 Hours!
                  </Heading>

                  <Flex
                    w="100%"
                    justify="center"
                    m="0rem 0"
                    gap="1rem"
                    direction={["column", "column", "row"]}
                  >
                    <Link href="https://wa.link/str-maven" target="_blank">
                      <Button
                        bg="#25D366"
                        color={white}
                        size="lg"
                        h="3.5rem"
                        px="2rem"
                        borderRadius="10rem"
                        _hover={{
                          textDecoration: "none",
                          bg: shade(0.3, "#25D366"),
                        }}
                      >
                        <Icon
                          as={FaWhatsapp}
                          color={white}
                          fontSize="1.5rem"
                          mr={2}
                        />
                        <Heading
                          fontSize="1.125rem"
                          color={white}
                          fontWeight="600"
                        >
                          Chat on WhatsApp
                        </Heading>
                      </Button>
                    </Link>

                    <Link href="https://t.me/strmaven" target="_blank">
                      <Button
                        bg="#0088cc"
                        color={white}
                        size="lg"
                        h="3.5rem"
                        px="2rem"
                        borderRadius="10rem"
                        _hover={{
                          textDecoration: "none",
                          bg: shade(0.3, "#0088cc"),
                        }}
                      >
                        <Icon
                          as={FaTelegramPlane}
                          color={white}
                          fontSize="1.5rem"
                          mr={2}
                        />
                        <Heading
                          fontSize="1.125rem"
                          color={white}
                          fontWeight="600"
                        >
                          Chat on Telegram
                        </Heading>
                      </Button>
                    </Link>

                    {/* <Button
                    bg={primary}
                    color={white}
                    size="lg"
                    h="3.5rem"
                    px="2rem"
                    borderRadius="10rem"
                    _hover={{ textDecoration: "none", bg: secondary }}
                  >
                    <Icon
                      as={FaEnvelope}
                      color={white}
                      fontSize="1.25rem"
                      mr={2}
                    />
                    <Heading fontSize="1.125rem" color={white} fontWeight="600">
                      Email us
                    </Heading>
                  </Button> */}
                  </Flex>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              w="100%"
              align="center"
              justify="center"
              m="6rem auto 0"
              //   position="absolute"
              position="relative"
              //   bottom="1.5rem"
            >
              <Flex
                gap="1rem"
                m="0 auto"
                direction={["column", "column", "row"]}
              >
                <Flex
                  direction="column"
                  // bg="#F8FD91"
                  bg="#5ECCA0"
                  p="1.5rem 1.5rem"
                  borderRadius="1.5rem"
                  flexShrink={0}
                  position="relative"
                  textAlign="center"
                  // minW="300px"
                  // maxW="300px"
                  align="center"
                  justify="flex-start"
                >
                  <Image src={heroicon1} h="4rem" />
                  <Heading
                    fontSize="1.55rem"
                    color={textBlack}
                    m="1rem 0 0.25rem"
                  >
                    Improve Your Ratings
                  </Heading>
                  <Text
                    m="1rem 0 0"
                    fontSize="md"
                    color={textBlack}
                    fontWeight="600"
                    maxW="290px"
                    letterSpacing="-0.1px"
                  >
                    Remove harmful reviews to significantly enhance your
                    property’s overall rating quickly and effectively.
                  </Text>
                </Flex>
                <Flex
                  direction="column"
                  bg="#F8FD91"
                  // bg="#5ECCA0"
                  p="1.5rem 1.5rem"
                  borderRadius="1.5rem"
                  flexShrink={0}
                  position="relative"
                  textAlign="center"
                  // minW="300px"
                  // maxW="300px"
                  align="center"
                  justify="flex-start"
                >
                  <Image src={heroicon2} h="4rem" />
                  <Heading
                    fontSize="1.55rem"
                    color={textBlack}
                    m="1rem 0 0.25rem"
                  >
                    Get More Bookings
                  </Heading>
                  <Text
                    m="1rem 0 0"
                    fontSize="md"
                    color={textBlack}
                    fontWeight="600"
                    maxW="290px"
                    letterSpacing="-0.1px"
                  >
                    Achieving super-host status (4.8 stars) helps businesses
                    receive 2x more bookings.
                  </Text>
                </Flex>
                <Flex
                  direction="column"
                  // bg="#E4E4E4"
                  bg={textBlack}
                  p="1.5rem 1.5rem"
                  borderRadius="1.5rem"
                  flexShrink={0}
                  position="relative"
                  textAlign="center"
                  // minW="300px"
                  // maxW="300px"
                  align="center"
                  justify="flex-start"
                >
                  <Image src={heroicon3} h="3.5rem" m="0.25rem 0 0.25rem" />
                  <Heading fontSize="1.55rem" color={white} m="1rem 0 0.25rem">
                    Stress free Service
                  </Heading>
                  <Text
                    m="1rem 0 0"
                    fontSize="md"
                    color={white}
                    fontWeight="600"
                    maxW="290px"
                    letterSpacing="-0.1px"
                  >
                    Just let us know which review you'd like us to remove, and
                    we’ll handle everything from there.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/* <Flex
                direction="column"
                align="flex-start"
                maxW="420px"
                m="0rem auto 0"
              >
                <Flex align="flex-start">
                  <Icon
                    as={FaQuoteRight}
                    color={secondary}
                    fontSize="1.75rem"
                    mr="1rem"
                    position="relative"
                    top="5px"
                  />
                  <Text
                    fontSize="1.125rem"
                    color={white}
                    fontWeight="500"
                    // textTransform="uppercase"
                  >
                    I was able to remove a negative review in less than 24
                    hours. I highly recommend STR Maven Rating Rescue.
                  </Text>
                </Flex>
                <Stack gap="0.25rem" m="0.5rem 0 0" pl="3rem">
                  <Text fontSize="md" color={white} fontWeight="500">
                    - John Doe, Airbnb Host
                  </Text>
                </Stack>
              </Flex> */}
      </Flex>
    </>
  );
};

export default Hero;
