import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Text,
  Flex,
} from "@chakra-ui/react";
import { textBlack, white } from "../utils/colors";

const faqs = [
  {
    question: "How do I request a review removal?",
    answer:
      "To request a review removal, simply provide us with the details of the review you want removed through one of our contact channels: Call, Email, Messenger, Whatsapp, Instagram, or Telegram. We’ll take it from there!",
  },
  {
    question: "How long does it take to remove a review?",
    answer:
      "Our team works quickly to address your request. Typically, the review removal process takes up to 24 hours, though it can be completed within 72 hours depending on the complexity.",
  },
  {
    question: "Will I be notified once the review is removed?",
    answer:
      "Yes, you will receive a confirmation once the review has been successfully removed. We also provide updates throughout the process to keep you informed.",
  },
  {
    question: "What if the review isn’t removed or I’m not satisfied?",
    answer:
      "Our service is designed to be effective and hassle-free. If for any reason the review isn’t removed or you’re not satisfied, please contact us, and we will work to resolve the issue promptly. Payment is only required after successful removal.",
  },
  {
    question: "How does payment work?",
    answer:
      "You only pay for the service after the review has been successfully removed. We ensure that the process is safe, secure, and transparent.",
  },
];

const Faq = () => {
  return (
    <Flex
      direction="column"
      align="center"
      w="100%"
      p={["3rem 1.5rem", "3rem 1.5rem", "5rem 0"]}
      bg={white}
    >
      <Flex
        w="100%"
        align="center"
        justify="center"
        m="0 auto"
        direction="column"
        textAlign="center"
      >
        <Heading
          fontSize={["2rem", "2rem", "3.5rem"]}
          color={textBlack}
          fontWeight="800"
        >
          Frequently Asked Questions
        </Heading>
      </Flex>

      <Flex w="100%" maxW="800px" direction="column" m="3rem 0 0">
        <Accordion allowToggle>
          {faqs.map((faq, index) => (
            <AccordionItem key={index} border="none" mb="1rem">
              <h2>
                <AccordionButton
                  //   _expanded={{ bg: "primary", color: "white" }}
                  p="1.5rem"
                  borderRadius="0.5rem"
                  boxShadow="md"
                >
                  <Box flex="1" textAlign="left" fontWeight="bold">
                    <Heading
                      fontSize="1.125rem"
                      color={textBlack}
                      fontWeight="600"
                      letterSpacing="0.5px"
                    >
                      {faq.question}
                    </Heading>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel
                pb={4}
                p="1.5rem"
                bg="transparent"
                borderRadius="0.5rem"
                boxShadow="none"
                transition="max-height 0.3s ease-in-out, padding 0.3s ease-in-out"
              >
                <Text
                  fontSize="lg"
                  fontWeight="500"
                  color={textBlack}
                  lineHeight="1.8"
                >
                  {faq.answer}
                </Text>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Flex>
  );
};

export default Faq;
