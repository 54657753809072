import React from "react";
// import { Link } from "react-router-dom";
import { Image, Flex, Button, Heading, Icon, Link } from "@chakra-ui/react";
import logo from "../assets/icons/riceonelogo.svg";
import { textBlack, white, yellow } from "../utils/colors";
import strmavenlogoprimary from "../assets/icons/strmaven-logo-primary.svg";
import { shade } from "polished";
import {
  FaWhatsapp,
  FaTelegramPlane,
  FaEnvelope,
  FaPhone,
} from "react-icons/fa";

const Header = ({ scrollToHowItWorks, scrollToFaq, scrollToHero }) => {
  return (
    <Flex
      align="center"
      position={["fixed", "fixed", "fixed"]}
      top="1rem"
      left="50%"
      bg={white}
      // h="80px"
      zIndex={900}
      boxShadow="0 0 0 .5px rgba(0,0,0,.1)"
      transform="translateX(-50%)"
      borderRadius="100px"
      p="1rem 2rem"
      minW={["initial", "initial", "1000px"]}
      w={["initial", "initial", "initial"]}
    >
      <Flex
        w="100%"
        maxW={["100%", "100%", "1200px"]}
        m="0 auto"
        align="center"
        h="100%"
        justify={["center", "center", "space-between"]}
      >
        <Flex align="center">
          <Image
            src={strmavenlogoprimary}
            alt="STRMaven Reviews"
            h={["2rem", "2rem", "2.25rem"]}
            mr={["0rem", "0rem", "3rem"]}
            position="relative"
            // top={[
            //   "-3px",
            //   "-3px",
            //   "-3px"
            // ]}
            onClick={scrollToHero}
            _hover={{ cursor: "pointer" }}
          />

          <Flex align="center" gap="2rem" display={["none", "none", "flex"]}>
            <Heading
              fontSize="md"
              color={textBlack}
              fontWeight="600"
              letterSpacing="1px"
              onClick={scrollToHowItWorks}
              _hover={{
                cursor: "pointer",
                color: textBlack,
                textDecoration: "underline",
              }}
            >
              How it works
            </Heading>
            <Heading
              fontSize="md"
              color={textBlack}
              fontWeight="600"
              letterSpacing="1px"
              onClick={scrollToFaq}
              _hover={{
                cursor: "pointer",
                color: textBlack,
                textDecoration: "underline",
              }}
            >
              Faq
            </Heading>
          </Flex>
        </Flex>

        <Flex justify="flex-end" gap="1rem" display={["none", "none", "flex"]}>
          <Link href="https://wa.link/str-maven" target="_blank">
            <Button
              bg="#25D366"
              color={white}
              size="md"
              px="1rem"
              borderRadius="10rem"
              _hover={{ textDecoration: "none", bg: shade(0.3, "#25D366") }}
            >
              <Icon as={FaWhatsapp} color={white} fontSize="1.5rem" />
            </Button>
          </Link>

          <Link href="https://t.me/strmaven" target="_blank">
            <Button
              bg="#0088cc"
              color={white}
              size="md"
              px="1rem"
              borderRadius="10rem"
              _hover={{ textDecoration: "none", bg: shade(0.3, "#0088cc") }}
            >
              <Icon as={FaTelegramPlane} color={white} fontSize="1.5rem" />
            </Button>
          </Link>

          <Link href="tel:+17628890778" target="_blank">
            <Button
              bg={yellow}
              color={textBlack}
              size="md"
              px="1rem"
              borderRadius="10rem"
              _hover={{ textDecoration: "none", bg: shade(0.3, yellow) }}
            >
              <Icon as={FaPhone} color={textBlack} fontSize="1.25rem" />
            </Button>
          </Link>

          <Link href="mailto:hello@strmaven.com" target="_blank">
            <Button
              bg={yellow}
              color={textBlack}
              size="md"
              px="1rem"
              borderRadius="10rem"
              _hover={{ textDecoration: "none", bg: shade(0.3, yellow) }}
            >
              <Icon as={FaEnvelope} color={textBlack} fontSize="1.25rem" />
            </Button>
          </Link>
        </Flex>
      </Flex>

      <Link to="/">
        {/* <Image src={logo} alt="RiceBali" h={["2rem", "2rem", "2.5rem"]} /> */}
      </Link>
    </Flex>
  );
};

export default Header;
