import React from "react";
import { Flex, Heading, Image, Text, Icon, Button } from "@chakra-ui/react";
import {
  white,
  textBlack,
  primary,
  textPrimary,
  secondary,
  green,
  yellow,
} from "../utils/colors";
// import main3 from "../assets/images/main3.jpg";
// import TestimonialSwiper from "../components/partials/TestimonialSwiper";
// import mobilemain from "../assets/images/mobilemain.jpg";
import { useMediaQuery } from "react-responsive";
// import map from "../assets/icons/map.svg";
import { FaAirbnb } from "react-icons/fa";
import airbnb from "../assets/icons/airbnblogo.svg";
import map2 from "../assets/icons/map2.svg";

const Benefits = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  console.log("isMobile", isMobile);

  // console.log("containerwidth", window.innerWidth);

  return (
    <Flex
      // minH="100vh"
      bg={white}
      w="100%"
      align-="center"
      justify="center"
      p={["5rem 1.5rem 0", "5rem 1.5rem 0", "8rem 0rem 0"]}
    >
      <Flex w="100%" maxW={["100%", "100%", "1200px"]} m="0 auto">
        <Flex direction="column" w="100%">
          <Heading
            fontSize={["2rem", "2rem", "2.5rem"]}
            color={textBlack}
            fontWeight="600"
            textAlign={["left", "left", "center"]}
            maxW={["100%", "100%", "1000px"]}
            m={["0 ", "0 ", "0 auto"]}
          >
            Negative reviews can significantly impact your rental business,
            driving potential guests away and lowering your occupancy rates.
          </Heading>

          {/* <Flex
            w="100%"
            justify="center"
            m={["5rem 0", "5rem 0", "8rem 0"]}
            position="relative"
          >
            <Image
              src={isMobile ? mobilemain : main3}
              alt="Main"
              h="100%"
              w="100%"
              borderRadius="1.5rem"
            />

            <Flex
              position="absolute"
              bg="radial-gradient(circle, rgba(0,0,0,0.4) 20%, rgba(0,0,0,0.1) 100%)"
              w="100%"
              h="100%"
              borderRadius="1.5rem"
            />

            <Flex
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              h={["100%", "100%", "initial"]}
              w="100%"
            >
              <TestimonialSwiper />
            </Flex>
          </Flex> */}

          <Flex
            w="100%"
            justify="space-between"
            p={["5rem 0", "5rem 0", "8rem 0"]}
            direction={isMobile ? "column" : "row"}
          >
            <Flex w={["100%", "100%", "30%"]}>
              <Flex
                direction="column"
                w="100%"
                // p={["0 1.5rem", "0 1.5rem", "0 0rem"]}
              >
                <Text
                  fontSize={["lg", "lg", "md"]}
                  color={green}
                  fontWeight="600"
                  maxW={["80%", "80%", "initial"]}
                >
                  We have helped hosts from all over the world
                </Text>
                <Heading
                  fontSize="2.25rem"
                  color={textBlack}
                  fontWeight="800"
                  m="1rem 0 2rem"
                  lineHeight="1.2"
                >
                  400
                  <Text as="span" fontWeight="600" fontFamily="Inter">
                    +{" "}
                  </Text>
                  hosts from 20
                  <Text as="span" fontWeight="600" fontFamily="Inter">
                    +{" "}
                  </Text>
                  countries
                </Heading>
                <Flex
                  bg={yellow}
                  direction="column"
                  align="center"
                  justify="center"
                  p="3rem 1.5rem 4rem"
                  textAlign="center"
                  borderRadius="1.5rem"
                >
                  <Icon as={FaAirbnb} color={textBlack} fontSize="2.5rem" />
                  {/* <Image src={airbnb} h="2rem" /> */}
                  <Heading
                    fontSize="2rem"
                    color={textBlack}
                    m="1rem 0 1rem"
                    fontWeight="700"
                  >
                    99% Success Rate
                  </Heading>
                  <Heading
                    fontSize="lg"
                    color={textBlack}
                    letterSpacing="0.5px"
                    maxW={["100%", "100%", "300px"]}
                  >
                    99% success rate in removing negative reviews from Airbnb
                    and other platforms
                  </Heading>
                </Flex>
                {/* <Button
                  bg={secondary}
                  color={white}
                  size="lg"
                  h="3.5rem"
                  px="2rem"
                  borderRadius="10rem"
                  m="1.5rem 0 0"
                >
                  Get Started
                </Button> */}
              </Flex>
            </Flex>

            <Flex
              w={["100%", "100%", "70%"]}
              pl={["0", "0", "10%"]}
              m={["1.5rem 0 0", "1.5rem 0 0", "0"]}
            >
              <Image src={map2} w="100%" />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Benefits;
